<template>
  <div>
    <b-spinner
      v-if="isLoading"
      class="align-middle"
    />
    <div v-else>
      <div class="d-flex justify-content-between">
        <h2
          class="mb-2"
        >
          {{ reportName ? reportName : 'Create Tuition Card' }}
        </h2>

        <!-- Dropdown -->
        <b-dropdown
          variant="primary"
          toggle-class="p-0"
          no-caret
          right
          class="h-100 ml-1"
        >

          <template
            #button-content
          >
            <feather-icon
              icon="GridIcon"
              size="19"
              class="m-50"
            />
          </template>

          <b-dropdown-item
            v-if="canExportPDF"
            v-b-modal.export-pdf-modal
          >
            <b-spinner
              v-if="isPdfLoading"
              small
            />
            <span>Export To PDF File</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canExportCSV"
            :disabled="isBalanceReportLoading"
            @click="balanceReportDownload"
          >
            <b-spinner
              v-if="isBalanceReportLoading"
              small
            />
            <span>Export Balance Report</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <!-- <h2
        v-else
        class="mb-2"
      >
        Create Tuition Card
      </h2> -->

      <!-- Program Selection -->
      <b-card>
        <b-row
          align-v="end"
        >
          <b-col
            cols="12"
          >
            <div class="d-flex justify-content-between student-selection">
              <span>Student Selection</span>
              <div class="text-primary cursor-pointer">
                <span
                  class="mr-1"
                  @click="selectAllStudents"
                >Select All</span>
                <span
                  @click="deselectAllStudents"
                >Deselect All</span>
              </div>
            </div>
            <v-select
              v-model="selectedStudents"
              class="w-100 select-student"
              label="full_name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="studentsList"
              :clearable="false"
              :reduce="student => student.id"
              :placeholder="selectStudentPlaceholder"
              :close-on-select="false"
              :deselect-from-dropdown="true"
              :autoscroll="false"
              multiple
              @close="updateChargesAndPayments"
              @option:deselected="updateChargesAndPayments"
            />
          </b-col>
        </b-row>
      </b-card>

      <!-- Payments Groups -->
      <b-card no-body>
        <app-collapse>
          <app-collapse-item
            title
            :is-visible="true"
          >
            <template #header>
              <h5
                class="d-flex align-items-center mb-0"
              >
                <feather-icon
                  icon="CopyIcon"
                  size="18"
                  class="mr-1"
                />
                Payments Groups
              </h5>
            </template>

            <b-table
              :items="paymentsList"
              :fields="paymentTableColumns"
              primary-key="id"
            >
              <!-- Column: master_payment_type -->
              <template #cell(master_payment_type)="data">
                <PaymentTypeSelect
                  :ref-id="data.item.refId"
                  :master-payment-type-list="paymentsMasterPaymentTypeList"
                  :value="data.item.master_payment_type"
                  :type="'payment'"
                  @selectMasterPaymentType="selectMasterPaymentType"
                  @addNewMasterPaymentType="addNewMasterPaymentType"
                  @removeMasterPaymentType="removeMasterPaymentType"
                />
              </template>
            </b-table>

            <!-- <b-row
              v-if="paymentsList.length > 0"
            >
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-end mt-2"
              >
                <b-button
                  class="ml-1"
                  variant="primary"
                  @click="updatePaymentsTypes"
                >
                  <span>Apply</span>
                </b-button>
                <b-button
                  class="ml-1"
                  variant="outline-primary"
                >
                  <span>Cancel</span>
                </b-button>
              </b-col>
            </b-row> -->
          </app-collapse-item>
        </app-collapse>
      </b-card>

      <!-- Charges Groups -->
      <app-collapse class="mb-2">
        <app-collapse-item
          title
          :is-visible="true"
        >
          <template #header>
            <h5
              class="d-flex align-items-center mb-0"
            >
              <feather-icon
                icon="CopyIcon"
                size="18"
                class="mr-1"
              />
              Charges Groups
            </h5>

            <b-form-checkbox
              v-model="unboundPayments"
              class="d-inline-flex justify-content-center align-items-center mr-2"
            >
              Include unlinked Payments
            </b-form-checkbox>
          </template>

          <b-table
            :items="filteredChargesGroups"
            :fields="chargeTableColumns"
            primary-key="id"
          >
            <!-- Column: type -->
            <template #cell(hide)="data">
              <b-form-checkbox
                v-if="data.item.name !== 'Unlinked Payment'"
                v-model="data.item.hidden"
                class="d-inline-flex justify-content-center align-items-center mr-1"
                @change="hide(data.item)"
              />
            </template>
            <!-- Column: type -->
            <template #cell(type)="data">
              <span>{{ data.item.name }}</span>
            </template>
            <!-- Column: master_payment_type -->
            <template #cell(master_payment_type)="data">
              <PaymentTypeSelect
                :ref-id="data.item.refId"
                :master-payment-type-list="chargesMasterPaymentTypeList"
                :value="data.item.master_payment_type"
                :type="'charge'"
                @selectMasterPaymentType="selectMasterPaymentType"
                @addNewMasterPaymentType="addNewMasterPaymentType"
                @removeMasterPaymentType="removeMasterPaymentType"
              />
            </template>
          </b-table>

        </app-collapse-item>
      </app-collapse>

      <!-- Filter Groups -->
      <app-collapse class="mb-2">
        <app-collapse-item
          title
          :is-visible="true"
        >
          <template #header>
            <h5
              class="d-flex align-items-center"
            >
              <feather-icon
                icon="FilterIcon"
                size="18"
                class="mr-1"
              />
              Filters Groups
            </h5>
          </template>
          <b-card>
            <b-row>
              <b-col
                cols="6"
              >
                <h5 class="mb-2">
                  Charge Types
                </h5>

                <b-form-checkbox
                  v-for="option in filteredChargeList"
                  :key="option.refId"
                  v-model="selectedCharges"
                  :value="option.name"
                >
                  {{ option.name }}
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="6"
              >
                <h5 class="mb-2">
                  Payment Types
                </h5>

                <b-form-checkbox-group
                  v-model="selectedPaymentMethods"
                  :options="paymentMethodsList"
                  text-field="type"
                  value-field="id"
                  stacked
                />
              </b-col>
            </b-row>
          </b-card>
        </app-collapse-item>
      </app-collapse>

      <b-card
        class="mb-2"
      >
        <div class="d-flex justify-content-between align-items-center mb-1">
          <h5
            v-if="selectedProgram"
            class="mr-1"
          >
            {{ selectedProgram.name }}
          </h5>
          <h5 v-else>
            Program name
          </h5>
          <v-select
            v-model="selectedStudentForGenerateReport"
            class="w-25"
            label="full_name"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="studentsListForGenerateReport"
            :clearable="false"
            :placeholder="'Select Student'"
          />
        </div>

        <b-table
          ref="fafsaReportTable"
          :items="fafsaReportTableItems"
          :fields="fafsaReportTableColumns"
          primary-key="id"
          show-empty
          :busy.sync="isBusy"
          empty-text="No matching records found"
        >
          <template #table-busy>
            <div class="my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <!-- Column: Date -->
          <template #cell(pay_date)="data">
            <span>{{ data.item.pay_date | dateToLocal }}</span>
          </template>

          <!-- Column: Id -->
          <template #cell(id)="data">
            <span :id="data.item.id">{{ data.item.id | shortId }}</span>
            <b-tooltip
              triggers="hover"
              :target="data.item.id"
              :delay="{ show: 100, hide: 50 }"
            >
              {{ data.item.id }}
            </b-tooltip>
          </template>

          <!-- Column: charges -->
          <template #cell(charges)="data">
            <span v-if="data.item.model_type == 'Charge'">{{ data.item.amount }}</span>
          </template>

          <!-- Column: charges -->
          <template #cell(payments)="data">
            <span v-if="data.item.model_type == 'Payment'">{{ data.item.amount }}</span>
          </template>

        </b-table>

        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-end mt-2"
          >
            <div>
              <b-form-checkbox
                v-model="includeScheduledPayments"
                class="d-inline-flex justify-content-center align-items-center mr-1"
              >
                Include Scheduled Payments
              </b-form-checkbox>

              <b-form-checkbox
                v-model="isAlreadyStarted"
                class="d-inline-flex justify-content-center align-items-center"
              >
                Filter By Billable Date
              </b-form-checkbox>

              <b-button
                class="ml-1"
                variant="outline-primary"
                :disabled="!selectedStudents.length"
                @click="generateReport"
              >
                <span class="mr-25 align-middle">Generate Card</span>
              </b-button>
              <b-button
                v-if="canUpdateReport"
                class="ml-1"
                variant="primary"
                @click="$bvModal.show('save-report-modal')"
              >
                <span class="mr-25 align-middle">Save Card</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <SaveReportModal
      :report-name="reportName"
      @saveReport="saveReport"
    />

    <export-pdf-modal
      :is-loading="isPdfLoading"
      @handleExportPdf="pdfExport"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  // BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BButton,
  BTable,
  BSpinner,
  BTooltip,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import { filters } from '@core/mixins/filters'

import PaymentTypeSelect from '@/views/admin/fafsa-reports/fafsa-report-details/PaymentTypeSelect.vue'
import SaveReportModal from '@/views/admin/fafsa-reports/fafsa-report-details/save-report-modal/SaveReportModal.vue'
import ExportPdfModal from '@/views/components/fafsa-export-pdf-modal/ExportPdfModal.vue'
import useFafsaReportDetails from '@/views/admin/fafsa-reports/fafsa-report-details/useFafsaReportDetails'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'FafsaReportDetails',
  components: {
    AppCollapse,
    AppCollapseItem,

    BRow,
    BCol,
    BCard,
    // BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BButton,
    BTable,
    BSpinner,
    BTooltip,
    BDropdown,
    BDropdownItem,

    vSelect,

    PaymentTypeSelect,
    SaveReportModal,
    ExportPdfModal,
  },
  mixins: [downloadExportFile, filters],
  data() {
    return {
      isLoading: false,
      reportId: this.$router.currentRoute.query?.id,
      reportName: '',
      selectedProgram: null,
      // programsList: [],
      selectedStudents: [],
      studentsList: [],
      selectStudentPlaceholder: 'Loading ...',
      studentsListForGenerateReport: [],
      selectedStudentForGenerateReport: null,
      paymentsList: [],
      paymentMethodsList: [],
      chargesList: [],
      refundsList: [],
      chargesMasterPaymentTypeList: [],
      paymentsMasterPaymentTypeList: [],
      selectedCharges: [],
      selectedPaymentMethods: [],
      renamePaymentMethods: [],
      renameCharges: [],
      fafsaReportTableItems: [],
      application_id: null,
      isBusy: false,
      sortBy: 'created_at',
      sortDesc: false,
      isPdfLoading: false,
      isBalanceReportLoading: false,
      isAlreadyStarted: false,
      includeScheduledPayments: false,
      unboundPayments: false,
    }
  },
  setup() {
    const {
      paymentTableColumns,
      chargeTableColumns,
      fafsaReportTableColumns,
      fetchReport,
      postReport,
      updateReport,
      fetchProgram,
      fetchStudentsList,
      fetchChargesList,
      fetchTransactionsList,
      fetchFafsaGeneratedList,
      exportPdf,
      downloadBalanceReport,
      fetchMasterPaymentTypes,
      postMasterPaymentType,
      deleteMasterPaymentType,
      programId,
    } = useFafsaReportDetails()

    return {
      paymentTableColumns,
      chargeTableColumns,
      fafsaReportTableColumns,
      fetchReport,
      postReport,
      updateReport,
      fetchProgram,
      fetchStudentsList,
      fetchChargesList,
      fetchTransactionsList,
      fetchFafsaGeneratedList,
      exportPdf,
      downloadBalanceReport,
      fetchMasterPaymentTypes,
      postMasterPaymentType,
      deleteMasterPaymentType,
      programId,
    }
  },
  computed: {
    filteredChargeList() {
      return this.chargesList.filter(item => item.name !== 'Unlinked Payment')
    },
    filteredChargesGroups() {
      return this.chargesList.filter(item => !item.invisible)
    },
    canUpdateReport() {
      return this.$can('update', permissionSubjects.FinancialReport)
    },
    canExportCSV() {
      return this.$can('export', permissionSubjects.ExportCSV)
    },
    canExportPDF() {
      return this.$can('export', permissionSubjects.ExportPDF)
    },
  },
  watch: {
    selectedStudents(val) {
      this.setStudentsListForGenerateRoprt(val)
    },
    async programId(val) {
      await this.fetchProgram(val)
        .then(res => {
          this.selectedProgram = res
        })
      this.resetStudent()
    },
    unboundPayments() {
      this.useUnlinkedPaymentsInChargesTypes()
    },
  },
  async created() {
    if (this.reportId) {
      this.isLoading = true

      await this.setMasterPaymentTypesList()

      await this.fetchReport(this.reportId)
        .then(response => {
          this.reportName = response.name
          this.selectedStudents = response.user_ids
          this.isAlreadyStarted = response.filter?.is_already_started || false
          this.includeScheduledPayments = response.filter?.include_scheduled_payments || false
          this.unboundPayments = response.filter?.include_unbound_payments || false
          this.renameCharges = response.filter.mapped_charges_names || []
          this.renamePaymentMethods = response.filter.mapped_payment_methods_names || []
          this.fetchProgram(response.program_id)
            .then(res => {
              this.selectedProgram = res
            })
            .then(() => {
              this.setStudentsList().then(() => {
                this.setStudentsListForGenerateRoprt(this.selectedStudents)
              })
            })
            .then(() => {
              this.setCharesAndPayments()
                .then(() => {
                  this.selectedCharges = response.filter.description
                  this.selectedPaymentMethods = response.filter.type
                })
                .finally(() => {
                  this.isLoading = false
                })
            })
        })
    } else {
      this.reportName = 'Create Tuition Card'
      await this.fetchProgram(this.programId)
        .then(res => {
          this.selectedProgram = res
        })
      await this.setStudentsList()
      await this.setMasterPaymentTypesList()
    }
  },
  methods: {
    async setStudentsList() {
      const queryParams = {
        programId: this.selectedProgram.id,
        role: 'child',
        sortBy: 'last_name',
        sortDesc: false,
        has_accepted_application_by_program: this.selectedProgram.id,
      }

      this.selectStudentPlaceholder = 'Loading ...'

      await this.fetchStudentsList(queryParams).then(response => {
        this.studentsList = response
        this.selectStudentPlaceholder = 'Select Student'
      })
    },
    resetStudent() {
      this.selectedStudents = []
      this.resetChargesAndPayments()
      this.setStudentsList()
    },
    setStudentsListForGenerateRoprt(selectedStudents) {
      this.studentsListForGenerateReport = this.studentsList.filter(item => selectedStudents.includes(item.id))
      this.selectedStudentForGenerateReport = this.studentsListForGenerateReport[0] || null
    },
    async setCharges() {
      const queryParams = {
        program_id: this.selectedProgram.id,
        application_user_id: this.selectedStudents,
        visible_for_fafsa: 1,
        is_without_charge: 0,
      }

      await this.fetchTransactionsList(queryParams).then(response => {
        this.chargesList = response
          .filter(item => item.model_type === 'Charge')
          .reduce((arr, currentValue) => {
            if (currentValue.description) {
              const existingChargeIndex = arr.findIndex(item => item?.name === currentValue.description)
              if (existingChargeIndex === -1) {
                const obj = {
                  refId: currentValue.id,
                  transactions: [currentValue.id],
                  name: currentValue.description,
                  master_payment_type: null,
                  hidden: false,
                }
                const renameCharge = this.renameCharges.find(item => item.originalName === currentValue.description)
                if (renameCharge) {
                  obj.master_payment_type = renameCharge.mappedName
                  obj.hidden = renameCharge.hidden
                }
                arr.push(obj)
                return arr
              }
              return arr
            }
            return arr
          }, [])

        this.selectedCharges = this.chargesList.map(charge => charge.name)
      })

      const queryParamsForRefunds = {
        program_id: this.selectedProgram.id,
        application_user_id: this.selectedStudents,
        visible_for_fafsa: 1,
        type: 'Refund',
      }
      await this.fetchTransactionsList(queryParamsForRefunds).then(response => {
        this.refundsList = response
          .reduce((arr, currentValue) => {
            if (currentValue.description) {
              const existingChargeIndex = arr.findIndex(item => item?.name === currentValue.description)

              if (existingChargeIndex === -1) {
                const obj = {
                  refId: currentValue.id,
                  transactions: [currentValue.id],
                  name: currentValue.description,
                  master_payment_type: null,
                  hidden: false,
                }
                const renameCharge = this.renameCharges.find(item => item.originalName === currentValue.description)
                if (renameCharge) {
                  obj.master_payment_type = renameCharge.mappedName
                  obj.hidden = renameCharge.hidden
                }
                arr.push(obj)
                return arr
              }
              return arr
            }
            return arr
          }, [])

        this.refundsList.reduce((arr, item) => {
          arr.push(item)
          return arr
        }, this.chargesList)

        this.refundsList.reduce((arr, item) => {
          arr.push(item.name)
          return arr
        }, this.selectedCharges)

        this.refundsList.reduce((arr, item) => {
          if (arr.findIndex(charge => charge.originalName === item.name) === -1) {
            arr.push({
              originalName: item.name,
              mappedName: item.master_payment_type,
              hidden: item.hidden,
            })
          }
          return arr
        }, this.renameCharges)
      })

      await this.useUnlinkedPaymentsInChargesTypes()
    },
    async setPayments() {
      const queryParams = {
        program_id: this.selectedProgram.id,
        application_user_id: this.selectedStudents,
        visible_for_fafsa: 1,
        is_without_charge: 1,
      }

      await this.fetchTransactionsList(queryParams).then(response => {
        this.paymentsList = response
          .filter(item => item.model_type === 'Payment')
          .reduce((arr, currentValue) => {
            const existingPaymentMethodIndex = arr.findIndex(item => item.type === currentValue.type)
            const renamePaymentMethod = this.renamePaymentMethods.find(item => item.originalName === currentValue.type)

            if (existingPaymentMethodIndex === -1) {
              const obj = {
                refId: currentValue.id,
                transactions: [currentValue.id],
                type: currentValue.type,
                master_payment_type: null,
              }
              if (renamePaymentMethod) {
                obj.master_payment_type = renamePaymentMethod.mappedName
              }
              arr.push(obj)
              return arr
            }
            return arr
          }, [])

        this.paymentMethodsList = response
          .filter(item => item.model_type === 'Payment')
          .reduce((arr, currentValue) => {
            if (arr.every(item => item !== currentValue.type)) {
              arr.push(currentValue.type)
            }
            return arr
          }, [])
        this.selectedPaymentMethods = [...this.paymentMethodsList]
      })
    },
    async setCharesAndPayments() {
      await this.setCharges()
      await this.setPayments()
    },
    resetChargesAndPayments() {
      this.chargesList = []
      this.selectedCharges = []
      this.paymentsList = []
      this.paymentMethodsList = []
      this.selectedPaymentMethods = []
    },
    updateChargesAndPayments() {
      if (this.selectedStudents.length > 0) {
        this.resetChargesAndPayments()
        this.setCharesAndPayments()
      }
    },
    async setMasterPaymentTypesList() {
      await this.fetchMasterPaymentTypes()
        .then(response => {
          this.chargesMasterPaymentTypeList = response.filter(item => item.type === 0)
          this.paymentsMasterPaymentTypeList = response.filter(item => item.type === 1)
        })
    },
    async addNewMasterPaymentType(data) {
      await this.postMasterPaymentType(data).then(() => {
        this.setMasterPaymentTypesList()
      })
    },
    async removeMasterPaymentType(id) {
      await this.deleteMasterPaymentType(id).then(() => {
        this.setMasterPaymentTypesList()
      })
    },
    async useUnlinkedPaymentsInChargesTypes() {
      if (this.unboundPayments) {
        const charge = this.chargesList.find(item => item.name === 'Unlinked Payment')
        if (!charge) {
          const renamedCharge = this.renameCharges.find(item => item.originalName === 'Unlinked Payment')
          this.chargesList.push({
            master_payment_type: renamedCharge ? renamedCharge.mappedName : 'Unlinked Payment',
            name: 'Unlinked Payment',
            refId: 'Unlinked Payment',
            invisible: false,
          })
        } else {
          charge.invisible = false
        }

        if (!this.renameCharges.find(item => item.originalName === 'Unlinked Payment')) {
          this.renameCharges.push({
            originalName: 'Unlinked Payment',
            mappedName: 'Unlinked Payment',
          })
        }
      } else {
        const charge = this.chargesList.find(item => item.name === 'Unlinked Payment')
        if (charge) {
          charge.invisible = true
        }
      }
    },
    async selectMasterPaymentType(data) {
      if (data.type === 'payment') {
        const payment = this.paymentsList.find(item => item.refId === data.id)
        payment.master_payment_type = data.value

        const updatedPaymentMethod = this.renamePaymentMethods.find(item => item.originalName === payment.type)
        if (updatedPaymentMethod) {
          updatedPaymentMethod.mappedName = payment.master_payment_type
        } else {
          this.renamePaymentMethods.push({
            originalName: payment.type,
            mappedName: payment.master_payment_type,
            hidden: payment.hidden,
          })
        }
      }
      if (data.type === 'charge') {
        const charge = this.chargesList.find(item => item.refId === data.id)
        charge.master_payment_type = data.value
        const updatedCharge = this.renameCharges.find(item => item.originalName === charge.name)
        if (updatedCharge) {
          updatedCharge.mappedName = charge.master_payment_type
        } else {
          this.renameCharges.push({
            originalName: charge.name,
            mappedName: charge.master_payment_type,
            hidden: charge.hidden,
          })
        }
      }
    },
    async hide(e) {
      const foundCharge = this.renameCharges.find(charge => charge.originalName === e.name)
      foundCharge.hidden = e.hidden
    },
    async generateReport() {
      this.isBusy = true
      const selectedMappedChargesNames = this.renameCharges
        .filter(item => this.selectedCharges.includes(item.originalName) || item.originalName === 'Unlinked Payment')
      const selectedMappedPaymentsNames = this.renamePaymentMethods
        .filter(item => this.selectedPaymentMethods.includes(item.originalName))
      const queryParams = {
        report_id: this.reportId,
        program_id: this.selectedProgram.id,
        user_id: this.selectedStudentForGenerateReport.id,
        description: this.selectedCharges.length ? this.selectedCharges : ['no'],
        type: this.selectedPaymentMethods.length ? this.selectedPaymentMethods.map(item => item.toString()) : ['no'],
        visible_for_fafsa: 1,
        sortDesc: 0,
        is_already_started: Number(this.isAlreadyStarted),
        include_scheduled_payments: Number(this.includeScheduledPayments),
        include_unbound_payments: Number(this.unboundPayments),
        mapped_payment_methods_names: selectedMappedPaymentsNames,
        mapped_charges_names: selectedMappedChargesNames,
      }
      this.fafsaReportTableItems = await this.fetchFafsaGeneratedList(queryParams)
      this.isBusy = false
    },
    async pdfExport(isSinglePdfFile, templateId, isSendByEmail, selectedEmail) {
      this.isPdfLoading = true
      const selectedMappedChargesNames = this.renameCharges
        .filter(item => this.selectedCharges.includes(item.originalName) || item.originalName === 'Unlinked Payment')
      const selectedMappedPaymentsNames = this.renamePaymentMethods
        .filter(item => this.selectedPaymentMethods.includes(item.originalName))
      const queryParams = {
        report_id: this.reportId,
        program_id: this.selectedProgram.id,
        user_ids: this.selectedStudents,
        filter: {
          description: this.selectedCharges,
          type: this.selectedPaymentMethods,
          is_already_started: this.isAlreadyStarted,
          include_scheduled_payments: this.includeScheduledPayments,
          include_unbound_payments: this.unboundPayments,
          mapped_payment_methods_names: selectedMappedPaymentsNames,
          mapped_charges_names: selectedMappedChargesNames,
        },
        is_single_pdf_file: isSinglePdfFile,
        template_id: templateId,
        send_by_email: false,
      }
      if (isSendByEmail) {
        queryParams.send_by_email = true
        queryParams.selected_email = selectedEmail
      }
      this.isPdfLoading = true
      await this.exportPdf(queryParams)
        .then(response => {
          if (response.status === 200 && isSendByEmail === false) {
            this.downloadFile(response.data, `${this.selectedProgram.name}(Tuition Cards)`, 'zip')
          }
        }).finally(() => {
          this.isPdfLoading = false
          this.$bvModal.hide('export-pdf-modal')
        })
    },
    async balanceReportDownload() {
      const selectedMappedChargesNames = this.renameCharges
        .filter(item => this.selectedCharges.includes(item.originalName) || item.originalName === 'Unlinked Payment')
      const selectedMappedPaymentsNames = this.renamePaymentMethods
        .filter(item => this.selectedPaymentMethods.includes(item.originalName))
      const queryParams = {
        program_id: this.selectedProgram.id,
        user_ids: this.selectedStudents,
        filter: {
          description: this.selectedCharges,
          type: this.selectedPaymentMethods,
          is_already_started: this.isAlreadyStarted,
          include_scheduled_payments: this.includeScheduledPayments,
          include_unbound_payments: this.unboundPayments,
          mapped_payment_methods_names: selectedMappedPaymentsNames,
          mapped_charges_names: selectedMappedChargesNames,
        },
      }
      this.isBalanceReportLoading = true
      await this.downloadBalanceReport(queryParams).then(response => {
        if (response.status === 200) {
          this.downloadFile(response.data, `${this.selectedProgram.name}(Balance Report)`, 'csv')
          this.isBalanceReportLoading = false
        }
      }).finally(() => {
        this.isBalanceReportLoading = false
      })
    },
    async saveReport(name) {
      const queryParams = {
        name,
        program_id: this.selectedProgram.id,
        user_ids: this.selectedStudents,
        filter: {
          description: this.selectedCharges,
          type: this.selectedPaymentMethods,
          is_already_started: this.isAlreadyStarted,
          include_scheduled_payments: this.includeScheduledPayments,
          include_unbound_payments: this.unboundPayments,
          mapped_payment_methods_names: this.renamePaymentMethods,
          mapped_charges_names: this.renameCharges,
        },
      }
      this.$bvModal.hide('save-report-modal')
      if (this.reportId) {
        await this.updateReport(this.reportId, queryParams).then()
      } else {
        await this.postReport(queryParams).then(response => {
          this.reportId = response.id
        })
      }
    },
    selectAllStudents() {
      this.selectedStudents = this.studentsList.map(student => student.id)
      this.updateChargesAndPayments()
    },
    deselectAllStudents() {
      this.selectedStudents = []
      this.resetChargesAndPayments()
    },
  },
}
</script>

<style lang="scss" scoped>
.student-selection {
  margin-bottom: calc(0.438rem + 1px);
}
</style>
<style lang="scss">
.select-student {
  .vs__dropdown-toggle {
    max-height: 36px;
    overflow: hidden;
  }
}
</style>
