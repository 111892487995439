<template>
  <v-select
    :ref="refId"
    :value="value"
    class="no-border"
    :label="'name'"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :options="masterPaymentTypeList"
    :clearable="false"
    :searchable="false"
    :placeholder="`Select Master ${type} type`"
    :reduce="option => option.name"
    @option:selected="selectMasterPaymentType"
  >
    <!-- :close-on-select="false" -->
    <template #option="{ name, id }">
      <div class="d-flex align-items-center justify-content-between cursor-default">
        <span>{{ name }}</span>
        <div
          @mousedown.stop.prevent="removeMasterPaymentType(id)"
        >
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="15"
          />
        </div>
      </div>
    </template>

    <template #list-footer>
      <li class="mx-1 py-1 border-top border-top-secondary">
        <b-form-group
          label="Name"
        >
          <b-form-input
            ref="footerInput"
            v-model="newTypeName"
            type="text"
            placeholder="New type"
            @click="handleInputFocus(refId)"
            @keyup.enter="addNewMasterPaymentType"
          />
        </b-form-group>
        <div
          class="w-100 text-primary text-center font-weight-bold cursor-pointer"
          @click="addNewMasterPaymentType"
        >
          <span class="ml-25 align-middle">+ Add option</span>
        </div>
      </li>
    </template>
  </v-select>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BFormInput,

    vSelect,
  },
  props: {
    refId: {
      type: String,
      default: '',
    },
    masterPaymentTypeList: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newTypeName: '',
    }
  },
  methods: {
    selectMasterPaymentType(value) {
      const data = { id: this.refId, type: this.type, value: value.name }
      this.$emit('selectMasterPaymentType', data)
    },
    handleInputFocus(refId) {
      // Focus footerInput manually
      this.$refs.footerInput.focus()
      // Keep the dropdown open
      this.$refs[refId].open = true
    },
    removeMasterPaymentType(id) {
      this.$emit('removeMasterPaymentType', id)
    },
    addNewMasterPaymentType() {
      if (this.newTypeName) {
        const data = {
          type: this.type === 'charge' ? 0 : 1,
          name: this.newTypeName,
        }
        this.$emit('addNewMasterPaymentType', data)
        this.newTypeName = ''
      }
    },
  },
}
</script>

<style>
.no-border .vs__dropdown-toggle {
  border: none;
}
.no-border .vs__dropdown-option--selected::after {
  display: none;
}
</style>
